















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SidebarPurchase extends Vue {
 public data = [
    {item : "Requisitions", visibility: false,   collapse: "collapse-1", 
    child: [{item: "Purchase Requisitions", route : "/requisitions/purchase-requisitions" }, 
    {item: "Purchase Requisitions Cancellations", route : "/requisitions/purchase-requisitions-cancellations"  }]},
    {item : "Enquiries", visibility: false,  collapse: "collapse-2", child:
     [{item: "Purchase Enquiries", route : "/enquires/purchase-enquiries" },
      {item: "Purchase Enquiries Cancellations", route : "/enquires/purchase-enquiries-cancellations"} , 
      {item: "Purchase Enquiries Againts Requisitions", route : "/enquires/purchase-enquiries-againts-requisitions"}]}, 
    {item: "Quotations",  visibility: false,  collapse: "collapse-3", 
    child: [{item: "Purchase Quotations", route : "/quotations/purchase-quotations"},
    {item: "Purchase Quotations Againts Enquiries", route : "/quotations/purchase-quotations-againts-enquires" }, 
     ]},
    {item: "Orders",   visibility: false, collapse: "collapse-4", child:
    [{ item: "Purchase Orders", route : "/orders/purchase-order"} 
    ,{item: "Purchase Orders Againts Requisitions", route : "/orders/purchase-order-againts-requisitions"}, 
    {item: "Purchase Orders Againts Quotations", route : "/orders/purchase-order-againts-quotations"},
    {item: "Purchase Order Cancellation", route : "/orders/purchase-order-cancellation"},
]},
    {item: "Receipts", visibility: false, collapse: "collapse-5",
     child: [{item:"Material Receipts ", route : "/receipts/material-receipts"}, 
     {item: "Material Receipts Againts Orders", route : "/receipts/material-receipts-againts-orders"}, 
     ]},
     {item: "Invoices",   visibility: false, collapse: "collapse-6", child:
    [{ item: "Purchase Voucher", route : "/invoices/purchase-voucher"} 
    ,{item: "Cash Purchase Voucher", route : "/invoices/cash-purchase-voucher"}, 
    {item: "Purchase Voucher Againts Orders", route : "/invoices/purchase-voucher-againts-orders"},
    {item: "Purchase Voucher Againts Receipts", route : "/invoices/purchase-voucher-againts-receipts"},
]},
    {item: "Return", visibility: false, collapse: "collapse-7", 
    child: [{item: "Material Return", route : "/return/material-return"}, 
    {item: "Purchase Return", route : "/return/purchase-return"}, 
    {item: "Purchase Return with Invoice Reference", route : "/return/purchase-return-with-invoice-reference"},
    
    
    ]}
  ];

    mounted() {
      let index = this.data.findIndex(x => x.item === this.$route.name);
      if(index >= 0){
         this.data[index]['visibility'] = true;
      }
      
    }
    
}
